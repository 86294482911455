import {createReducer} from '@reduxjs/toolkit'
import {
  describeSeatingPlan,
  selectPrice,
  selectLocation,
  setShowAccessibilityMode,
  addPlaceToBasket,
  selectPlace,
  unselectPlace,
  setSeatingError,
} from '../actions/seating'
import {SeatingState} from '../types'
import {checkout} from '../actions/checkout'
import {closeNativeModal} from '../actions/modals'

const defaultState: SeatingState = {
  plan: null,
  loading: false,
  error: null,
  places: [],
  selectedPrice: null,
  selectedZone: null,
  showAccessibilityMode: false,
}

export const seating = createReducer<SeatingState>(defaultState, builder => {
  builder
    .addCase(describeSeatingPlan.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
    .addCase(selectPrice, (state, action) => ({
      ...state,
      selectedPrice: action.payload,
    }))
    .addCase(addPlaceToBasket.fulfilled, (state, action) => ({...state, places: action.payload.places}))
    .addCase(selectLocation, (state, action) => ({...state, selectedZone: action.payload}))
    .addCase(checkout.fulfilled, state => ({...state, error: null, loading: false}))
    .addCase(checkout.pending, state => ({...state, loading: true}))
    .addCase(checkout.rejected, state => ({...state, loading: false}))
    .addCase(setSeatingError, (state, action) => ({...state, error: action.payload, loading: false}))
    .addCase(setShowAccessibilityMode, (state, action) => ({...state, showAccessibilityMode: action.payload}))
    .addCase(closeNativeModal, state => ({...state, loading: false}))
    .addCase(selectPlace.fulfilled, (state, action) => ({...state, places: action.payload.places}))
    .addCase(unselectPlace.fulfilled, (state, action) => ({...state, places: action.payload.places}))
})
